<template>
    <v-card>
        <v-card-title>
            <h3>Notifications</h3>
            <v-spacer />
            <v-text-field
                v-model="keywords"
                append-icon="mdi-magnify"
                label="Search Notifications..."
                clearable
                single-line
                hide-details
            />
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn
                        @click="newNotificationForm"
                        color="primary"
                        icon
                        dark
                        fab
                    >
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </template>
                <span>New Notification</span>
            </v-tooltip>
        </v-card-title>
        <v-card-text>
            <notifications-grid
                :keywords.sync="keywords"
                :reload.sync="reloadVar"
            />
        </v-card-text>
        <v-dialog v-model="notificationFormDialog" persistent max-width="600">
            <notification-form
                :notification.sync="formNotification"
                @notification-form-saved="
                    closeNotificationForm()
                    reloadGrid()
                "
                @notification-form-closed="closeNotificationForm()"
            />
        </v-dialog>
    </v-card>
</template>
<script>
import NotificationsGrid from '@/views/notifications/NotificationsGrid'
import NotificationForm from '@/views/notifications/NotificationForm'
export default {
    name: 'notifications',
    components: {
        'notifications-grid': NotificationsGrid,
        'notification-form': NotificationForm,
    },
    data: () => ({
        keywords: '',
        selectedNotification: {},
        reloadVar: 0,
        notificationFormDialog: false,
        formNotification: {},
    }),
    methods: {
        reloadGrid: function() {
            this.reloadVar++
        },
        newNotificationForm: function() {
            this.formNotification = {}
            this.notificationFormDialog = true
        },
        closeNotificationForm: function() {
            this.notificationFormDialog = false
        },
        editNotification: function(notification) {
            this.formNotification = notification
            this.formNotification.confirm_email = this.formNotification.email
            this.notificationFormDialog = true
        },
    },
}
</script>
