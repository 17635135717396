<template>
    <v-container fluid>
        <v-menu offset-x>
            <template v-slot:activator="{ on }">
                <v-btn
                    :disabled="selected.length < 1"
                    color="primary"
                    dark
                    v-on="on"
                >
                    With Selected...
                </v-btn>
            </template>

            <v-list>
                <v-list-item @click="deleteList">
                    <v-list-item-title>Delete</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        <ApolloQuery
            :query="require('@/graphql/notification/allNotifications.gql')"
            :variables="{ garbage: rando }"
        >
            <template v-slot="{ result: { loading, error, data }, isLoading }">
                <v-progress-linear indeterminate v-if="isLoading" />
                <v-data-table
                    v-if="data && !isLoading"
                    v-model="selected"
                    :items="data.allNotifications"
                    :headers="headers"
                    item-key="id"
                    :search="keywords"
                    :items-per-page.sync="itemsPerPage"
                    show-select
                    :footer-props="{ itemsPerPageOptions }"
                >
                    <template v-slot:item.action="{ item }">
                        <v-icon small @click="promptForDelete(item)">
                            mdi-delete
                        </v-icon>
                    </template>

                    <template v-slot:item.data.persistent="{ item }">
                        {{ item.data.persistent.toUpperCase() }}
                    </template>
                    <template v-slot:item.data.persistent_until="{ item }">
                        {{ formatUntilDate(item.data.persistent_until) }}
                    </template>
                    <template v-slot:item.read_at="{ item }">
                        {{ formatReadAtDate(item.read_at) }}
                    </template>
                </v-data-table>
                <v-alert type="error" v-else-if="error">
                    There was an error loading data. The server said...<br />
                    <strong>{{ error }}</strong>
                </v-alert>
            </template>
        </ApolloQuery>
        <v-dialog
            v-model="deleteDialog"
            persistent
            max-width="320"
            v-if="selectedNotification.id"
        >
            <v-card>
                <v-card-title class="headline"
                    >Delete Notification?</v-card-title
                >
                <v-card-text>
                    <p>
                        You are about to delete the notification
                        <strong>{{ selectedNotification.data.title }}</strong
                        >. This cannot be undone. Are you sure you want to
                        delete this notification?
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="red darken-1"
                        text
                        @click="deleteDialog = false"
                        >{{ $t('cancel') }}</v-btn
                    >
                    <v-btn
                        color="primary darken-1"
                        text
                        @click="deleteNotification(selectedNotification)"
                        >{{ $t('delete') }}</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="deleteListDialog"
            persistent
            max-width="320"
            v-if="selected.length > 0"
        >
            <v-card>
                <v-card-title class="headline"
                    >Delete Notifications?</v-card-title
                >
                <v-card-text>
                    <p>
                        You are about to delete
                        {{ selected.length }} notifications. This cannot be
                        undone. Are you sure you want to delete selected
                        notifications.
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="red darken-1"
                        text
                        @click="deleteListDialog = false"
                        >{{ $t('cancel') }}</v-btn
                    >
                    <v-btn
                        color="primary darken-1"
                        text
                        @click="deleteSelectedNotifications()"
                        >{{ $t('delete') }}</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import moment from 'moment'

export default {
    name: 'NotificationsGrid',
    components: {
        // "notification-card": NotificationCard
    },
    props: {
        keywords: {
            type: String,
            required: true,
        },
        reload: {
            type: Number,
            default: 0,
        },
    },
    data: () => ({
        itemsPerPageOptions: [25, 50, 100],
        itemsPerPage: 25,
        deleteDialog: false,
        deleteListDialog: false,
        selectedNotification: {},
        selected: [],
        rando: 0,
        headers: [
            {
                text: 'Title',
                align: 'left',
                value: 'data.title',
            },
            { text: 'User', value: 'user.name' },
            { text: 'Show Daily', value: 'data.persistent' },
            { text: 'Show Daily Until Until', value: 'data.persistent_until' },
            { text: 'Read', value: 'read_at' },
            {
                text: 'Actions',
                align: 'right',
                sortable: false,
                value: 'action',
            },
        ],
    }),
    watch: {
        reload: function(val) {
            this.rando = val
        },
    },
    methods: {
        deleteList() {
            this.deleteListDialog = true
        },
        formatUntilDate(date) {
            if (date !== '2000-01-01 00:00:00') {
                return moment(date, 'YYYY-MM-DD').format('MM/DD/YYYY')
            } else {
                return ''
            }
        },
        formatReadAtDate(date) {
            if (date) {
                return moment(date, 'YYYY-MM-DD').format('MM/DD/YYYY')
            } else {
                return 'Unread'
            }
        },
        deleteSelectedNotifications() {
            this.$apollo
                .mutate({
                    // Query
                    mutation: require('@/graphql/notification/deleteNotifications.gql'),
                    // Parameters
                    variables: {
                        ids: this.selected.map(e => e.id).flat(),
                    },
                })

                // eslint-disable-next-line no-unused-vars
                .then(data => {
                    // report success
                    this.$store.dispatch('responseMessage', {
                        type: 'success',
                        title: 'Success',
                        text: 'Notifications deleted',
                    })
                    this.selected = []
                    this.rando++
                })
                .catch(error => {
                    // Error
                    console.error(error)
                    // update notification....
                })
            this.deleteListDialog = false
        },
        promptForDelete(notification) {
            this.selectedNotification = notification
            this.deleteDialog = true
        },
        deleteNotification(notification) {
            this.$apollo
                .mutate({
                    // Query
                    mutation: require('@/graphql/notification/deleteNotification.gql'),
                    // Parameters
                    variables: {
                        id: notification.id,
                    },
                })
                .then(data => {
                    // report success
                    this.$emit('notification-form-closed', data)
                    this.rando++
                })
                .catch(error => {
                    // Error
                    console.error(error)
                    // update notification....
                })
            this.deleteDialog = false
        },
        editNotification(notification) {
            this.$emit('edit-notification', notification)
        },
        showPassForm(notification) {
            this.$emit('show-password-form', notification)
        },
    },
}
</script>
